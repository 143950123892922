@media only screen and (max-width: 550px) {
  .product_right .product__feature_block {
    width: 35%;
    margin-right: 15%;
  }
}
@media only screen and (max-width: 640px) {
  .good.onSale .good_price:after {
    left: 17%;
  }
  .good.onSale .good_cart .quant-good-bskt {
    left: 10%;
  }
  .catalog_goods.layoutTable .good {
    width: 100%;
    display: block;
  }
  .catalog_goods.layoutTable .good_img__wrapper {
    width: 240px;
    min-width: 240px;
    border-right: 1px solid #E6E6E6;
    float: left;
  }
  .catalog_goods.layoutTable .good_title {
    border: 0;
    height: auto;
    overflow: visible;
    white-space: normal;
    padding: 0 20px;
    width: 40%;
    float: right;
    max-height: 40px;
    overflow: hidden;
    margin: 24px 0;
    margin-right: 5%;
  }
  .catalog_goods.layoutTable .good_price__block {
    float: right;
    display: block;
    width: 170px;
    min-width: 170px;
    height: auto;
    margin-right: 8%;
  }
  .catalog_goods.layoutTable .good_price-old {
    width: 100%;
  }
  .catalog_goods.layoutTable .good_price:after {
    top: 25%;
    left: 10%!important;
  }
  .catalog_goods.layoutTable .good .quant-good-bskt {
    left: 23%!important;
    top: 25%!important;
  }
}
@media only screen and (max-width: 1024px) {
  .ajax_cartPos {
    top: 0px!important;
  }
  #ajax_topCart .ajax_cartSummary a.ajax_button {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .product_right .order {
    margin-top: 10px;
  }
  .product_right .order label {
    flex-wrap: wrap;
  }
  .product__feature_item {
    flex-wrap: wrap;
    margin: 8px 0;
  }
  .product_right .product__feature_item.withImg .product__feature_name,
  .select_title {
    margin: 0;
    width: 100%;
    text-align: left;
  }
  .product_right .product__feature_item.withImg .product__feature_name {
    margin-bottom: 10px;
  }
  footer .footer_payment {
    bottom: 0;
  }
  .good_price:after {
    left: 25%;
  }
  .good .good_cart .quant-good-bskt {
    left: 17%;
  }
  .buy_oneClick {
    position: relative;
    width: 190px;
    margin: 0 auto;
  }
  .catalog_goods.layoutTable .good_description {
    display: none;
  }
  .zoom_container {
    display: none!important;
  }
  .wrapper {
    min-width: 480px;
    width: 100%;
  }
  header {
    min-width: 480px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  header #menu_toggle {
    display: block;
    position: absolute;
    right: 20px;
    top: 24px;
    height: 40px;
    width: 40px;
    padding-top: 19px;
    transition: all 0.4s ease-out;
    cursor: pointer;
    box-shadow: 0 0 6px 2px #898989;
  }
  header #menu_toggle:before,
  header #menu_toggle:after {
    content: '';
    display: block;
    position: absolute;
    left: 4px;
    top: 9px;
    width: 32px;
    height: 2px;
    background-color: #000;
    transition: all 0.5s ease-in-out;
    transform: rotate(0deg);
  }
  header #menu_toggle:after {
    top: 29px;
  }
  header #menu_toggle > div {
    width: 32px;
    height: 2px;
    margin: 0   auto;
    background-color: #000;
    transition: all 0.6s ease-in;
  }
  header #menu_toggle.show > div {
    background-color: transparent;
    transition: all 0.4s ease-out;
  }
  header #menu_toggle.show:before {
    transform: rotate(135deg);
    top: 20px;
    left: 4px;
  }
  header #menu_toggle.show:after {
    transform: rotate(-135deg);
    top: 20px;
    left: 4px;
  }
  header .header_menu {
    float: none;
    width: 100%;
    margin: 0;
    margin-top: 56px;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  header .header_menu.show {
    max-height: 180px;
  }
  header .header_menu__item {
    height: 45px;
    margin: 5px 0;
    width: 70%;
    text-align: center;
  }
  header .header_menu__item.current {
    border: none;
  }
  header .header_menu__item:hover .header_menu__link {
    border-color: transparent;
  }
  header .header_menu__link {
    height: 45px;
    line-height: 45px;
    font-size: 21px;
  }
  header .header_menu__link.basket-ajax {
    position: fixed;
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
    z-index: 10000;
    background: none !important;
    width: 50% !important;
    height: 60px;
    line-height: 60px;
    color: #ffe8c1;
    text-align: center;
    border: none !important;
    box-shadow: inset 0 0 10px 1px #ffe8c1;
    border-radius: 0;
  }
  header .header_phones_callMe {
    position: fixed;
    bottom: 0;
    left: auto;
    right: 0;
    top: auto;
    z-index: 10000;
    background: none !important;
    width: 50% !important;
    height: 60px;
    line-height: 60px;
    color: #ffe8c1;
    text-align: center;
    padding: 0;
    border: 2px solid #ffe8c1;
    border: none !important;
    box-shadow: inset 0 0 10px 1px #ffe8c1;
    border-radius: 0;
    font-size: 18px;
  }
  header .header_phones {
    text-align: center;
  }
  header .header_menu__link.ajax_bskt_fly {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
  }
  header .header_right {
    float: none;
  }
  header .header_languages {
    position: absolute;
    top: -38px;
    left: 1px;
    display: flex;
    margin: 0;
  }
  .header_wrapper .header_menu.level_1 {
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 0;
    display: none!important;
  }
  .header_drop {
    display: none!important;
    width: 100%;
  }
  .header_drop__wrapper {
    width: 100%;
  }
  .header_menu__item.header_cart {
    height: 0;
  }
  .pageNews {
    display: none;
  }
  .buttons_bg {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    height: 60px;
    background: url('../img/wood_bg.png');
  }
  .header_logo {
    left: 50%;
    transform: translateX(-50%);
  }
  footer {
    min-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  footer .footer_wrapper {
    padding: 46px 20px 20px 20px;
  }
  footer .footer_menu ul > li > a,
  footer .footer_menu ul > li {
    font-size: 17px;
  }
  footer a.footer_menu__title {
    display: none;
  }
  footer .social {
    width: 175px;
    margin: 15px auto 0 auto;
  }
  footer .social__item {
    width: 45px;
    height: 45px;
    background-size: 45px;
  }
  .good_price {
    font-size: 18px;
  }
  .pages {
    height: 30px;
    float: none;
    text-align: center;
    position: relative;
  }
  .catalog_header {
    height: auto;
  }
  .catalog_filter__sorting {
    margin-right: 0;
  }
  .catalog_filter__layout {
    margin-right: 10px;
  }
  .catalog_filter-bot {
    flex-wrap: wrap;
  }
  .catalog_filter-bot fieldset label {
    width: 50%;
  }
  .footer_payment {
    width: 100%;
  }
  .good {
    width: 48%;
    margin: 8px 1%;
  }
  .features {
    height: 232px;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
  }
  .features_item {
    width: 33.33%;
    min-width: 33.33%;
  }
  .features_item__text {
    font-size: 11px;
  }
  .product {
    display: block;
  }
  .product_left {
    width: 98%;
    padding: 1%;
  }
  .product_right {
    width: 98%;
    padding: 1%;
  }
  .product .product_tabs__why {
    display: none;
  }
  .product .product_tab__inner {
    font-size: 17px;
    line-height: 20px;
  }
  .product_price__block {
    padding-left: 25%;
  }
  .product .tab-panel {
    margin: 22px 0 20px 0;
  }
  .footer_wrapper {
    flex-wrap: wrap;
  }
  .footer_wrapper .footer_menu {
    width: 100%;
  }
  .wrapper_transparentGrey {
    min-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper_grey {
    min-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .slider-main {
    display: none;
  }
  .category_item__wrapper {
    width: 48%;
    margin: 8px 1%;
  }
  .advantages {
    flex-wrap: wrap;
    height: auto;
  }
  .advantages_item {
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #toTop {
    right: 4px;
    bottom: 64px;
  }
  .footer_menus__contacts {
    text-align: center;
  }
  .footer_menus__contacts li {
    margin-bottom: 8px;
  }
  .ya-map {
    width: 100%;
    margin: 0 auto;
  }
  .ya-map > ymaps {
    width: 100%!important;
    margin: 0 auto;
  }
  .category_title {
    font-weight: 600;
  }
  .wrapper-static img {
    max-width: 470px!important;
    height: auto;
  }
  .product_buy__amount input:only-of-type {
    box-shadow: inset 0 0 0 1px #898989;
    border: 0;
  }
  .cart_item {
    flex-wrap: wrap;
  }
  .cart_item__block {
    width: auto;
  }
  .cart_item__block.cart_item__img {
    width: 150px;
  }
  .empty_cart {
    min-height: 300px;
  }
  .order_call {
    min-height: 390px;
  }
  .news_page {
    width: auto;
    padding: 0 12px;
  }
  .mobile_ninja {
    display: block!important;
  }
  .desktop_ninja {
    display: none!important;
  }
}
