.news_page {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 30px;
  line-height: normal;
  font-size: 15px;
}
.news_page img {
  max-width: 100%;
}
.news_page .pages {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  transform: none;
  text-align: right;
}
.news_page .news_item {
  margin: 24px 0;
  padding: 12px 0;
  border-bottom: 1px dotted #898989;
}
.news_page .news_header {
  margin-bottom: 18px;
}
.news_page .news_title {
  font-size: 20px;
  max-width: 400px;
}
.news_page .news_date {
  float: right;
  font-size: 16px;
  color: brown;
}
.news_page .news_footer {
  margin-top: 18px;
}
.news_page .news_share {
  float: left;
}
.news_page .news_details {
  float: right;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
}
.news_page .news_details:hover {
  text-decoration: none;
}
