.video_item iframe {
  margin: 20px auto;
  display: block;
}
@media only screen and (max-width: 1000px) {
  .video_item iframe {
    max-width: 100%;
    height: 320px;
  }
}
