.market_banner {
  position: relative;
  background-color: #fff;
  height: 154px;
  padding-right: 30px;
  box-shadow: 0 0 4px 0 #E6E6E6;
}
.market_banner img {
  position: absolute;
  bottom: 0;
}
.market_banner__title {
  text-align: right;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 300;
  color: #712C18;
}
.market_banner__text {
  text-align: right;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 300;
  color: #712C18;
}
.catalog_header {
  position: relative;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  color: #712C18;
}
.pages {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #898989;
  font-size: 16px;
  font-weight: 600;
}
.pages a {
  color: #898989;
  text-decoration: none;
}
.pages .showAll {
  display: none;
}
.pages .prevAll {
  display: none;
}
.pages .nextAll {
  display: none;
}
.pages div > a,
.pages div > b,
.pages div > span {
  margin: 0 5px;
}
.pages div > span {
  color: #E6E6E6;
}
.pages div > a:hover {
  opacity: .7;
}
.pages div > b {
  color: #712C18;
  font-weight: 600;
}
.catalog_goods {
  margin: 30px -0.5%;
}
.catalog_goods.layoutTable .good {
  width: 96%;
  margin: 10px auto;
  float: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.catalog_goods.layoutTable .good_img__wrapper {
  width: 240px;
  min-width: 240px;
  border-right: 1px solid #E6E6E6;
}
.catalog_goods.layoutTable .good_description {
  display: block;
  width: 50%;
  padding: 0 30px;
}
.catalog_goods.layoutTable .good_title {
  border: 0;
  height: auto;
  overflow: visible;
  white-space: normal;
  padding: 0 20px;
  width: 20%;
  min-width: 170px;
}
.catalog_goods.layoutTable .good_price__block {
  display: block;
  width: 170px;
  min-width: 170px;
  height: auto;
  margin-right: 10px;
}
.catalog_goods.layoutTable .good_price-old {
  width: 100%;
}
.catalog_goods.layoutTable .good_price:after {
  left: 10%;
}
.catalog_goods.layoutTable .good .quant-good-bskt {
  left: 23%;
}
.catalog_goods.layoutTable .good.onSale .good_price:after {
  top: 25%;
  left: 10%;
}
.catalog_goods.layoutTable .good.onSale .quant-good-bskt {
  top: 25%;
  left: 23%;
}
.good {
  position: relative;
  float: left;
  width: 24%;
  border: 1px solid #E6E6E6;
  margin: 6px .5%;
  background-color: #fff;
}
.good .product_buy__button-not_in_stock {
  display: block;
  float: none;
  width: 100%;
  padding-left: 0;
  line-height: 36px;
  text-align: center;
  background-color: #898989;
  text-decoration: none;
}
.good .product_buy__button-not_in_stock:hover {
  opacity: 1;
  background-color: #a3a3a3;
}
.good .product_buy__button-not_in_stock:after {
  display: none;
}
.good__tag {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 26px;
  line-height: 26px;
  width: 60px;
  background: #A40000;
  color: #fff;
  text-align: center;
}
.good_description {
  display: none;
}
.good_img__wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  height: 175px;
  z-index: 10;
}
.good_img__wrapper img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease-out;
}
.good_img__wrapper:hover img {
  transform: translate(-50%, -50%) scale(1.15);
}
.good_img__wrapper img {
  z-index: 0;
}
.good_title {
  padding: 6px 12px 0 12px;
  display: block;
  color: #898989;
  text-decoration: none;
  font-size: 15px;
  border-top: 1px solid #E6E6E6;
  height: 45px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.4s ease-out;
}
.good_title:hover {
  color: #712C18;
}
.good_price__block {
  display: flex;
  position: relative;
  height: 36px;
  line-height: 36px;
  color: #898989;
}
.good_price {
  width: 100%;
  padding-left: 24px;
  text-align: center;
  background-color: #F5F7F4;
  border-top: 1px solid #E6E6E6;
  font-size: 15px;
  transition: all 0.4s ease-out;
}
.good_price:after {
  content: '';
  display: block;
  position: absolute;
  left: 35%;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 16px;
  background: url('../img/goods_cart.svg');
}
.good_price-old {
  display: none;
  width: 30%;
  text-align: center;
  text-decoration: line-through;
  font-size: 12px;
  background-color: #E6E6E6;
}
.good .good_cart {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.good .good_cart button {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
}
.good .good_cart button:focus {
  outline: none;
}
.good .good_cart .quant-good-bskt {
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 50%;
  background-color: #A40000;
  color: #fff;
  font-size: 10px;
  transition: all 0.4s ease-out;
}
.good.onSale .good_price:after {
  left: 25%;
}
.good.onSale .good_cart .quant-good-bskt {
  left: 20%;
}
.good.active .good_price {
  background-color: #A40000;
  color: #E6E6E6;
}
.good.active .good_quantity {
  opacity: 1;
  background-color: #E6E6E6;
}
.good.active .quant-good-bskt {
  background-color: #E6E6E6;
  color: #898989;
}
.good.active .good_price:after {
  background: url('../img/cart_white.svg') no-repeat;
  background-position: 1.5px 2px;
}
.goods_line {
  margin: 24px 0;
}
.goods_line__title {
  float: left;
  min-width: 19%;
  padding: 0;
  height: 36px;
  line-height: 36px;
  margin-bottom: 12px;
  text-align: center;
  background-color: #A40000;
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
}
.goods_line__block {
  display: flex;
  margin: 0 -0.5%;
}
.goods_line .good {
  width: 24%;
}
.goods_line .slider-popular .good {
  margin: 0 .5%;
}
.adaptiveToMobile {
  max-width: 100%;
}
.pages_bottom .pages {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  float: right;
}
.search_form__pages {
  position: relative;
}
.search_form__pages.search_form__pages--bottom .pages {
  position: relative;
  left: auto;
  right: 0;
  top: 0;
}
.search_form__pages .pages {
  position: absolute;
  left: auto;
  right: 20px;
  top: 30px;
  z-index: 15;
}
.search_form__pages .pages .showAll {
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 100px;
  background-color: #fff;
}
.search_form__pages .pages .showAll select {
  position: relative;
  border: none;
  width: 100px;
  height: 30px;
  padding-left: 8px;
  font-size: 12px;
  color: #898989;
}
.search_form__pages .pages .showAll select:focus {
  outline: none;
}
.search_form__pages .pages > div {
  display: none;
}
@media only screen and (max-width: 1400px) {
  .good {
    width: 24%;
  }
}
