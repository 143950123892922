.slick-slider {
  position: relative;
  z-index: 2;
}
.slick-slider .slick-slide {
  position: relative;
}
.slick-slider .slick-slide:focus {
  outline: none;
}
.slick-slider .slick-next,
.slick-slider .slick-prev {
  position: absolute;
  display: block;
  top: 45%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  padding: 0;
  cursor: pointer;
  background: none;
  outline: none;
  color: transparent;
  border-radius: 0;
  border: 26px solid #fff;
  border-width: 26px 16px;
  transition: border, background-color 0.45s, 0.45s ease-in, ease-out;
  z-index: 5;
}
.slick-slider .slick-next::after,
.slick-slider .slick-prev::after {
  content: '';
  display: block;
  position: absolute;
  background: none;
  border: none;
  height: 200px;
  width: 100px;
  transform: none;
  top: -102px;
  left: -50px;
}
.slick-slider .slick-prev {
  border-color: transparent #fff transparent transparent;
  left: -40px;
}
.slick-slider .slick-next {
  right: -40px;
  border-color: transparent transparent transparent #fff;
}
.slick-slider .slick-prev:hover {
  border-color: transparent #E6E6E6 transparent transparent;
}
.slick-slider .slick-next:hover {
  border-color: transparent transparent transparent #E6E6E6;
}
.slick-slider .slick-dots {
  display: block;
  height: 0;
  padding: 0;
  position: absolute;
  bottom: 29px;
  left: 25%;
  transform: translate(-50%);
  list-style: none;
  list-style-image: none;
  font-size: 0;
}
.slick-slider .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 8px;
  box-shadow: 0 0 0;
  width: 21px;
  height: 21px;
  background-color: transparent;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.4s ease-out;
}
.slick-slider .slick-dots li:after {
  content: '';
  position: absolute;
  left: 10px;
  top: 10px;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background-color: #E6E6E6;
  transition: box-shadow 0.4s ease-in-out;
  box-shadow: 0 0 0 5px #E6E6E6;
}
.slick-slider .slick-dots li button {
  visibility: hidden;
}
.slick-slider li.slick-active:after {
  box-shadow: 0 0 0 7px #E6E6E6;
}
.slider-popular {
  margin-bottom: 30px;
}
.slider-popular .slick-slide .slider-popular_img-wrapper {
  position: relative;
  margin: 0 auto;
  border-width: 1px 2px;
  width: 230px;
  height: 160px;
  overflow: hidden;
  background-color: #F8F9F9;
}
.slider-popular .slick-slide img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease-out;
}
.slider-popular .slick-slide:hover img {
  transform: translate(-50%, -50%) scale(1.15);
}
.slider-popular_item {
  text-decoration: none;
}
.slider-popular_title {
  width: 240px;
  margin: 0 auto;
  padding: 8px 5px;
  text-align: left;
  color: #898989;
  font-size: 15px;
  font-weight: 300;
  background-color: #F8F9F9;
}
.slider-popular_title span {
  font-size: 20px;
  font-weight: 600;
}
.slider-popular_price {
  display: block;
  position: absolute;
  right: 1px;
  bottom: 1px;
  width: 102px;
  height: 35px;
  line-height: 35px;
  background-color: #898989;
  font-size: 15px;
  color: #fff;
  text-align: center;
  font-weight: 300;
}
.slider-main {
  margin: 0 0 5px;
}
.slider-main .slick-list.draggable {
  width: 100%;
}
.slider-main .slider-main_item__wrapper {
  display: block;
  position: relative;
  background-color: #fff;
}
.slider-main .slider-main_item__wrapper a {
  display: block;
  position: relative;
  z-index: 10;
}
.slider-main .slider-main_item__wrapper a:focus {
  outline: none;
}
.slider-main .slider-main_item__wrapper:focus {
  outline: none;
}
.slider-main_button {
  position: absolute;
  bottom: 16px;
  right: 25%;
  transform: translate(50%);
  display: block;
  width: 230px;
  height: 33px;
  line-height: 33px;
  background-color: #A40000;
  z-index: 20;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 100;
  letter-spacing: 0.05em;
  transition: all 0.3s ease-out;
}
.slider-main_button:after {
  content: '';
  position: absolute;
  left: 50px;
  top: 7px;
  width: 19px;
  height: 18px;
  background: url('../img/cart_ico.svg') no-repeat;
}
.slider-main_button:hover {
  opacity: 0.7;
}
.slider-main a {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
}
.slider-main a img {
  width: 100%;
  height: auto;
  opacity: .87;
}
.slider-main.slick-slider .slick-next,
.slider-main.slick-slider .slick-prev {
  top: 50%;
}
.slider-main.slick-slider .slick-prev {
  left: -60px;
}
.slider-main.slick-slider .slick-next {
  right: -60px;
}
.slider-product {
  width: 100%;
  height: 350px;
  margin: 0;
  box-shadow: 0 0 5px 1px #33261E;
  cursor: zoom-in;
}
.slider-product .img__wrapper {
  position: relative;
  width: 300px;
  height: 350px;
  min-height: 300px !important;
  overflow: hidden;
  background-color: #fff;
}
.slider-product .img__wrapper img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.slider-product .img__wrapper img {
  max-width: 700px;
}
.notLoaded {
  opacity: 0!important;
}
.slider-main.notLoaded .slider-main_item__wrapper:not(:first-of-type) {
  position: absolute;
}
.slider-productNav {
  height: 120px;
  margin: 15px auto 0;
  width: 85%;
}
.slider-productNav .img__wrapper {
  position: relative;
  min-height: 100px;
  height: 100px !important;
  box-shadow: 0 0 5px 1px #33261E;
  margin: 10px 5px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}
.slider-productNav .img__wrapper img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.slider-productNav .img__wrapper img {
  width: auto;
  height: 100px;
}
.slider-productNav.slick-slider .slick-next,
.slider-productNav.slick-slider .slick-prev {
  display: none;
  transform: translate(-50%);
  left: 50%;
}
.slider-productNav.slick-slider .slick-prev {
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  border-color: transparent #ccc transparent transparent;
}
.slider-productNav.slick-slider .slick-prev:hover {
  border-color: transparent #898989 transparent transparent;
}
.slider-productNav.slick-slider .slick-next {
  right: -50px;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  border-color: transparent transparent transparent #ccc;
}
.slider-productNav.slick-slider .slick-next:hover {
  border-color: transparent transparent transparent #898989;
}
.slider-productNav.slick-slider .slick-next::after,
.slider-productNav.slick-slider .slick-prev::after {
  content: '';
  display: block;
  position: absolute;
  background: none;
  border: none;
  height: 100px;
  width: 50px;
  transform: none;
  top: -50px;
  left: -30px;
}
.slider-productNav.slick-slider .slick-prev::after {
  left: -20px;
}
.slider-static--rounds img {
  display: block;
  margin: 0 auto;
  height: 200px!important;
}
.slider-static--rounds.slick-slider .slick-next,
.slider-static--rounds.slick-slider .slick-prev {
  display: none;
}
@media only screen and (max-width: 767px) {
  .slider-main.slick-slider .slick-prev {
    left: -40px;
  }
  .slider-main.slick-slider .slick-next {
    right: -40px;
  }
}
