.contacts_page {
  font-size: 16px;
  color: #4D4D4D;
}
.contacts_page__phone {
  color: #832812;
  font-size: 18px;
}
.contacts_page h2 {
  color: #8D4A29;
  font-size: 15px;
  text-transform: uppercase;
  padding-bottom: 0;
}
.contacts_page__text {
  margin-bottom: 20px;
  color: #4D4D4D;
}
.contacts_page__text strong {
  font-weight: 600;
}
.contacts_page__text--faded {
  color: #B5B5B5;
}
.contacts_page__text--list li {
  position: relative;
}
.contacts_page__text--list li:before {
  content: '- ';
}
center ul {
  list-style: disc;
  padding-left: 40px;
}
.slider-static--foto {
  margin: 10px 40px;
}
.slider-static--foto a {
  display: block;
  height: 200px;
  line-height: 200px;
  text-align: center;
}
.slider-static--foto a:focus {
  outline: none;
}
.slider-static--foto .slick-slide img {
  display: inline;
}
.slider-static--foto.slick-slider .slick-prev {
  border-color: transparent #E6E6E6 transparent transparent;
  left: -40px;
}
.slider-static--foto.slick-slider .slick-prev:hover {
  border-color: transparent #898989 transparent transparent;
}
.slider-static--foto.slick-slider .slick-next {
  border-color: transparent transparent transparent #E6E6E6;
  right: -40px;
}
.slider-static--foto.slick-slider .slick-next:hover {
  border-color: transparent transparent transparent #898989;
}
@media only screen and (max-width: 1000px) {
  .foto-mobile td {
    display: block;
  }
  .foto-mobile img {
    display: block;
    margin: 0 auto;
    max-width: 480px;
    height: auto;
  }
}
