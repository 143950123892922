header {
  height: 152px;
  background: url('../img/wood_bg.png');
  padding-top: 41px;
  position: relative;
  min-width: 1000px;
}
.header_bg-white {
  background-color: #fff;
  height: 100%;
}
.header_logo {
  position: absolute;
  top: 20px;
  left: 0;
}
.header_logo img {
  height: 58px;
}
.header_wrapper {
  padding-top: 32px;
}
header .header_menu {
  float: left;
  display: flex;
  width: 41%;
  justify-content: space-between;
  margin-left: 200px;
}
header .header_menu__item {
  position: relative;
  height: 36px;
  line-height: 36px;
}
header .header_menu__item.current .header_menu__link,
header .header_menu__item.current .header_menu__link {
  color: #7D4A2E;
  background-color: #E6E6E6;
}
header .header_menu__link {
  display: block;
  height: 36px;
  padding: 0 10px;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: #33261E;
  border: 1px solid transparent;
  white-space: nowrap;
  transition: all 0.4s ease-out;
}
.header_cart span {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 3px;
  width: 23px;
  height: 23px;
  line-height: 23px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  background-color: #A40000;
  font-size: 11px;
  transition: all 0.3s ease-out;
}
.header_cart:hover span {
  opacity: 0.7;
}
.header_wrapper .header_menu.level_1 {
  display: none;
  position: absolute;
  min-width: 100%;
  width: auto;
  top: 30px;
  left: 0;
  margin: 0;
  padding: 5px 0 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  z-index: 500;
}
.header_wrapper .header_menu.level_1 > li {
  display: block;
  height: 32px;
  line-height: 32px;
}
.header_wrapper .header_menu.level_1 > li > a {
  display: block;
  padding: 0 16px;
  height: 100%;
  font-size: 16px;
  white-space: nowrap;
  color: #33261E;
  text-decoration: none;
  transition: all 0.2s ease-out;
}
.header_wrapper .header_menu.level_1 > li > a:hover {
  color: #A40000;
}
.header_wrapper .header_menu.level_1 > li:last-child > a {
  padding-bottom: 20px;
}
.header_right {
  float: right;
}
.header_social {
  margin-left: 10px;
  margin-top: 6px;
}
.header_phones__item {
  display: block;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 0 20px 0 32px;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  color: #33261E;
  background: no-repeat 0 0px;
  transition: all 0.3s ease-out;
}
.header_phones__item:hover {
  opacity: 0.75;
}
.header_phones-vi {
  background-image: url('../img/vi_head.svg');
}
.header_phones-wu {
  background-image: url('../img/wu_head.svg');
}
.header_phones_callMe {
  height: 20px;
  line-height: 20px;
  padding-right: 0;
  font-size: 15px;
  text-align: right;
  text-decoration: none;
}
.header_phones_callMe:hover {
  text-decoration: underline;
}
.header_languages {
  margin: 1px 22px 0 14px;
}
.header_languages__item {
  display: block;
  width: 53px;
  height: 34px;
  line-height: 34px;
  margin-bottom: 2px;
  background-color: #E6E6E6;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #898989;
  transition: all 0.4s ease-out;
}
.header_languages__item.active,
.header_languages__item:hover {
  background-color: #898989;
  color: #fff;
}
.header-articleSearch {
  float: right;
  position: relative;
  width: 160px;
  height: 36px;
}
.header-articleSearch__label {
  position: relative;
  height: 36px;
  line-height: 36px;
  display: block;
}
.header-articleSearch__submit {
  right: 0;
  top: 1px;
  content: '';
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #898989;
  background-image: url('../img/lupa.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  transition: all 0.2s ease-out;
}
.header-articleSearch__submit:hover {
  opacity: .7;
}
.header-articleSearch__input {
  width: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 8px;
  padding-right: 36px;
  border: 1px solid #E6E6E6;
}
.header-articleSearch__input:focus {
  outline-color: #E6E6E6;
}
.header_drop {
  position: fixed;
  top: -65px;
  width: 100%;
  height: 41px;
  line-height: 40px;
  background: url("../img/wood_bg.png") center bottom no-repeat;
  box-shadow: 0 0 15px;
  z-index: 9999;
  transition: all 0.3s ease-out;
}
.header_drop__wrapper {
  font-size: 14px;
  height: 41px;
  line-height: 40px;
  margin: 0 auto;
  position: relative;
  width: 700px;
  color: #ffe8c1;
  display: flex;
  justify-content: space-around;
}
.header_drop__wrapper > ul {
  float: left;
  list-style: none;
  padding: 0;
}
.header_drop__wrapper > ul > li {
  position: relative;
  float: left;
  font-size: 18px;
  font-family: 'OpenSans';
  text-transform: uppercase;
  padding: 0 12px;
}
.header_drop__wrapper > ul > li.current {
  text-decoration: none;
}
.header_drop__wrapper > ul > li > a {
  color: #fff;
  letter-spacing: .1em;
  text-decoration: none;
}
.header_drop__wrapper > ul > li > a:hover {
  text-decoration: underline;
}
.header_drop__wrapper > ul > li > ul {
  display: none;
  position: absolute;
  top: 37px;
  left: -1px;
  width: auto;
  min-width: 150px;
  font-size: 16px;
  list-style: none;
  text-transform: none;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  line-height: 22px;
  padding: 10px 0;
  margin: 3px 0 0;
  z-index: 9999;
}
.header_drop__wrapper > ul > li > ul > li {
  padding: 5px 20px;
  white-space: nowrap;
  color: #33261E;
}
.header_drop__wrapper > ul > li > ul > li > a {
  color: #33261E;
  text-decoration: none;
}
.header_drop__wrapper > ul > li > ul > li > a:hover {
  color: #A40000;
}
.header_drop__wrapper .cart-block {
  position: relative;
  float: right;
  margin: 7px 0 0 7px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  background: #ffe8c1;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #351600;
  z-index: 999;
}
.header_drop__logo {
  float: left;
}
.header_drop__logo img {
  height: 30px;
  filter: drop-shadow(0 0 5px #fff);
}
.header_drop__menu {
  display: flex;
  float: left;
}
.header_drop.shown {
  transform: translateY(65px);
}
#toTop {
  display: none;
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 120px;
  width: 70px;
  height: 70px;
  line-height: 90px;
  background-color: #898989;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  opacity: .6;
  cursor: pointer;
}
#toTop:after {
  content: '';
  display: block;
  position: absolute;
  top: 19px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 0px;
  height: 0px;
  border: 10px solid #fff;
  border-color: #fff transparent transparent #fff;
}
#toTop.shown {
  display: block;
}
@media only screen and (max-width: 900px) {
  .header_drop__wrapper {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1369px) {
  .header-articleSearch {
    margin-top: 9px;
  }
}
@media only screen and (max-width: 1000px) {
  .product_left {
    overflow: hidden;
  }
  .header-articleSearch {
    position: absolute;
    right: 0;
    top: -39px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 1150px) {
  .header_social {
    margin-top: 48px;
  }
}
@media only screen and (max-width: 1097px) {
  .header_social {
    margin-top: 12px;
  }
}
