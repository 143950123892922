.cart_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
}
.cart_item .material_type {
  color: #33261E;
  font-style: italic;
}
.cart_item__block {
  width: 145px;
  text-align: center;
  color: #898989;
  font-size: 15px;
  font-weight: 200;
  margin: 0 auto;
}
.cart_item__img {
  position: relative;
  min-width: 120px;
  width: 120px;
  height: 80px;
  overflow: hidden;
}
.cart_item__img img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cart_item__title {
  text-align: left;
  width: 300px;
  min-width: 300px;
  padding-left: 30px;
}
.cart_item.cart_headers .cart_item__img {
  height: 20px;
}
.cart_item .product_buy__amount {
  float: none;
  width: 105px;
  margin: 0 auto;
  height: 36px;
  line-height: 34px;
}
.cart_item .product_buy__amount input:only-of-type {
  width: 70px;
}
.cart_headers .cart_item__block {
  color: #7D4A2E;
}
.cart_headers .cart_item__title {
  height: 20px;
}
#deleteAll {
  position: relative;
  display: block;
  float: right;
  height: 36px;
  line-height: 36px;
  width: 240px;
  color: #898989;
  background-color: #E6E6E6;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  border: none;
  text-decoration: none;
  transition: all 0.4s ease-out;
}
#deleteAll:hover {
  color: #E6E6E6;
  background-color: #898989;
}
.cart_bottom__left {
  float: left;
}
.cart_bottom__right {
  float: right;
  color: #7D4A2E;
  font-size: 16px;
}
.cart_bottom__right button {
  position: relative;
  float: right;
  height: 36px;
  line-height: 36px;
  width: 240px;
  color: #898989;
  background-color: #E6E6E6;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  border: none;
  transition: all 0.4s ease-out;
}
.cart_bottom__right button:hover {
  color: #E6E6E6;
  background-color: #898989;
}
.cart_bottom__right button img {
  display: none;
}
.cart_bottom__right > div {
  height: 36px;
  line-height: 36px;
  margin: 8px 0;
}
.cart_bottom__right select {
  float: right;
  height: 36px;
  line-height: 36px;
  width: 200px;
  margin-left: 20px;
  border: 1px solid #E6E6E6;
  color: #898989;
}
.cart_bottom__right select:focus {
  outline: none;
}
.cart_bottom__right select option:focus {
  outline: none;
}
.cart_bottom__right #sum {
  text-align: right;
}
.cart_bottom__right #sum strong {
  font-size: 15px;
}
.cart_nav {
  height: 36px;
  line-height: 36px;
  margin: 16px 0 50px 0;
  color: #7D4A2E;
}
.cart_nav__back {
  float: left;
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
}
.cart_nav__back:hover {
  text-decoration: underline;
}
.cart_nav__submit {
  position: relative;
  float: right;
  height: 36px;
  line-height: 36px;
  width: 240px;
  color: #E6E6E6;
  background-color: #A40000;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  border: none;
  transition: all 0.4s ease-out;
}
.cart_nav__submit:after {
  content: '';
  display: block;
  position: absolute;
  width: 36px;
  height: 36px;
  left: 0;
  top: 0;
  background: #898989 url('../img/cart_order.png') no-repeat;
  background-position: 8px 9px;
  transition: all 0.4s ease-out;
}
.cart_nav__submit:hover {
  background-color: #310000;
}
.cart_nav__submit:hover::after {
  background-color: transparent;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.basket_items.order .less,
.basket_items.order .more {
  display: none;
}
.order label {
  position: relative;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px 12px;
}
.order label.required:after {
  content: '*';
  display: block;
  position: absolute;
  top: 5px;
  right: -10px;
  color: #A40000;
  font-size: 24px;
  font-weight: 600;
}
.order label input {
  position: relative;
  width: 244px;
  height: 36px;
  line-height: 36px;
  padding-left: 8px;
}
.order label input:focus {
  outline-color: #898989;
}
.order_form {
  margin-bottom: 30px;
}
.order .red_star {
  font-size: 24px;
  color: #A40000;
}
.order_sum {
  text-align: right;
  margin-bottom: 20px;
  padding-right: 20px;
}
.order textarea {
  min-width: 400px;
  min-height: 140px;
  margin-left: 12px;
  padding-left: 8px;
  padding-top: 8px;
}
.order textarea:focus {
  outline-color: #898989;
}
.order legend {
  margin-left: 12px;
  margin-bottom: 12px;
}
.order p {
  margin-left: 12px;
}
.order select {
  height: 36px;
  line-height: 36px;
  width: 244px;
}
.order select:focus {
  outline-color: #898989;
}
.order .control_buttons {
  height: 36px;
  line-height: 36px;
  margin: 12px;
  display: flex;
  justify-content: flex-start;
}
.order .control_buttons a {
  width: 220px;
  font-size: 16px;
  text-align: center;
}
.order .control_buttons a:hover {
  text-decoration: none;
}
.order .control_buttons button {
  color: #fff;
  width: 220px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #A40000;
  border: 0;
  transition: all 0.4s ease-out;
}
.order .control_buttons button:hover {
  filter: saturate(1.2);
}
.empty_cart {
  font-size: 18px;
  min-height: 180px;
  text-align: center;
}
.empty_cart p {
  color: #898989;
}
.empty_cart a {
  color: #A40000;
  text-decoration: none;
  font-weight: 600;
}
.empty_cart a:hover {
  text-decoration: underline;
}
.cart_list .product__feature_info {
  opacity: 0;
}
