@font-face {
  font-family: 'PFDpro';
  src: url('../fonts/pfdintextpro-light.eot');
  src: url('../fonts/pfdintextpro-light.eot?#iefix') format('embedded-opentype'), url('../fonts/pfdintextpro-light.woff') format('woff'), url('../fonts/pfdintextpro-light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
body {
  font-family: 'PFDpro', sans-serif;
  letter-spacing: 0.04em;
  background: url('../img/main_bg.png');
}
.wrapper {
  position: relative;
  max-width: 1400px;
  min-width: 1000px;
  margin: 0 auto;
}
.wrapper-static {
  margin-bottom: 30px;
}
.wrapper_transparentGrey {
  min-width: 1000px;
  background-color: rgba(214, 214, 214, 0.3);
}
.wrapper_darkBrown {
  background-color: #33261E;
}
.social {
  display: flex;
}
.social__item {
  display: block;
  margin: 0 6px;
  width: 27px;
  height: 27px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all 0.3s ease-out;
}
.social__item:hover {
  filter: brightness(0.7);
}
.social-vk {
  background-image: url('../img/vk_head.svg');
}
.social-fb {
  background-image: url('../img/fb_head.svg');
}
.social-ig {
  background-image: url('../img/ig_head.svg');
}
.breadcrumbs {
  color: #898989;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}
.breadcrumbs a {
  color: #898989;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.ya-map {
  width: 950px;
  margin: 0 auto;
}
.heighter {
  min-height: calc(100vh - 482px);
}
.payments_page {
  text-align: center;
}
.payments_page a {
  color: #A40000;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}
.payments_page a:hover {
  text-decoration: underline;
}
.payments_page h4 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
.payment_succ {
  color: green;
}
.payment_failed {
  color: red;
}
.mobile_ninja {
  display: none!important;
}
.popUp-wrapper {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000000;
}
.popUp-wrapper h2 {
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 24px;
  width: 100%;
  position: relative;
  background: url('../img/wood_bg.png');
  color: #fff;
  text-transform: uppercase;
}
.popUp-wrapper p {
  padding: 20px 20px 0 20px;
  font-size: 15px;
  color: #898989;
  text-align: center;
}
.popUp-wrapper p.message {
  color: green;
  font-weight: 600;
  font-size: 16px;
}
.popUp-wrapper .shape_error {
  display: block;
  padding: 0 20px 20px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #A40000;
  text-align: center;
}
.popUp-wrapper .order label {
  margin-left: 0;
}
.popUp-wrapper .order label input {
  border: 1px solid #898989;
}
.popUp-wrapper fieldset {
  padding: 20px;
}
.popUp-wrapper button {
  display: block;
  width: 200px;
  background-color: #A40000;
  border: 0;
  letter-spacing: .05em;
  color: #fff;
  text-transform: uppercase;
  height: 36px;
  line-height: 36px;
  margin: 0 auto;
}
.popUp-wrapper .close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  z-index: 15;
  cursor: pointer;
}
.popUp-wrapper .close:hover:after,
.popUp-wrapper .close:hover:before {
  background-color: #aaa;
}
.popUp-wrapper .close:after,
.popUp-wrapper .close:before {
  content: '';
  position: absolute;
  top: 9px;
  display: block;
  width: 20px;
  height: 2px;
  background-color: #eee;
}
.popUp-wrapper .close:after {
  transform: rotate(45deg);
}
.popUp-wrapper .close:before {
  transform: rotate(-45deg);
}
#click {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 440px;
  height: auto;
}
/* remove in prod */
.highslide-thumbstrip-horizontal-overlay {
  display: none !important;
}
.highslide-controls {
  display: none !important;
}
