.wrapper_grey {
  min-width: 1000px;
  background-color: #898989;
}
.advantages {
  margin-top: 26px;
  height: 115px;
  padding: 0;
  display: flex;
}
.advantages_item {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.advantages_item__ico {
  width: 53px;
  min-width: 53px;
  height: 53px;
  border-radius: 50%;
  background: #fff no-repeat;
  background-position: 50% 50%;
  margin-right: 5px;
}
.advantages_item__title {
  max-width: 120px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
}
.advantages_item:nth-of-type(1) .advantages_item__ico {
  background-image: url('../../images/advant1.png');
}
.advantages_item:nth-of-type(2) .advantages_item__ico {
  background-image: url('../../images/advant2.png');
}
.advantages_item:nth-of-type(3) .advantages_item__ico {
  background-image: url('../../images/advant3.png');
}
.advantages_item:nth-of-type(4) .advantages_item__ico {
  background-image: url('../../images/advant4.png');
}
.advantages_item:nth-of-type(5) .advantages_item__ico {
  background-image: url('../../images/advant5.png');
}
.advantages_item:nth-of-type(6) .advantages_item__ico {
  background-image: url('../../images/advant6.png');
}
.popular {
  padding-top: 32px;
}
.main_title {
  width: 230px;
  height: 36px;
  line-height: 36px;
  margin-left: 24px;
  margin-bottom: 20px;
  background-color: #A40000;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.pageNews {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 40px 0;
}
.pageNews_block {
  width: 30%;
  color: #898989;
  font-size: 15px;
}
.pageNews_block__title {
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #898989;
  text-decoration: none;
}
.order_call {
  margin-bottom: 30px;
  min-height: 202px;
}
.order_call label {
  position: relative;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px 12px;
}
.order_call label.required:after {
  content: '*';
  display: block;
  position: absolute;
  top: 5px;
  right: -10px;
  color: #A40000;
  font-size: 24px;
  font-weight: 600;
}
.order_call label input {
  position: relative;
  width: 244px;
  height: 36px;
  line-height: 36px;
  padding-left: 8px;
}
.order_call label input:focus {
  outline-color: #898989;
}
.order_call textarea {
  min-width: 400px;
  min-height: 140px;
  margin-left: 12px;
  padding-left: 8px;
  padding-top: 8px;
}
.order_call textarea:focus {
  outline-color: #898989;
}
.order_call legend {
  margin-left: 12px;
  margin-bottom: 12px;
}
.order_call .button {
  display: block;
  color: #fff;
  width: 220px;
  height: 36px;
  line-height: 36px;
  border-radius: 0;
  font-size: 16px;
  margin-left: 120px;
  margin-top: 12px;
  text-transform: uppercase;
  background: #A40000;
  border: 0;
  transition: all 0.4s ease-out;
}
.order_call .button:hover {
  filter: saturate(1.2);
}
.order_call p.message {
  color: #898989;
  font-size: 18px;
  line-height: 20px;
}
