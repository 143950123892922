#search_form {
  position: relative;
}
#search_form fieldset {
  position: relative;
}
#search_form .absolute-result {
  position: absolute;
  top: auto !important;
  right: auto !important;
  bottom: 0px !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(70%);
  box-sizing: border-box;
  width: 160px !important;
  height: 40px !important;
  line-height: 40px;
  background: url('../img/wood_bg.png');
  color: #ffe8c1;
  border: 1px solid #ffe8c1;
  border-radius: 5px;
  transition: box-shadow 0.3s;
}
#search_form .absolute-result:hover {
  box-shadow: inset 0 0 5px 1px #ffe8c1;
}
#search_form .price-block {
  text-align: center;
}
#search_form .price-block legend {
  text-align: left;
  margin-bottom: 3px;
}
#search_form .price-block input[type="number"] {
  width: 100px;
  height: 24px;
  line-height: 24px;
  padding-left: 8px;
}
#search_form .price-block .slider_h {
  margin-top: 8px;
}
#search_form .price-block .slider {
  max-width: 280px !important;
  margin: 0 auto;
  border: 0;
  border-radius: 0;
  background: #E6E6E6;
  height: 16px;
}
#search_form .price-block .slider > a {
  border: none;
  background: #712C18;
  border-radius: 0;
  height: 21px;
  width: 17px;
  top: 50%;
  transform: translateY(-50%);
}
#search_form .price-block .slider > a:focus {
  outline: none;
}
#search_form .price-block .slider .ui-slider-range {
  border: 0;
  border-radius: 0;
  background: none;
  background-image: linear-gradient(to right, #C15447, #CC6633);
}
#search_form label.not-act {
  opacity: .3;
}
.catalog_filter-top {
  height: 60px;
  line-height: 60px;
  background-color: #E6E6E6;
}
.catalog_filter__toggler {
  position: relative;
  float: left;
  width: 140px;
  height: 60px;
  border: none;
  background-color: #898989;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease-out;
}
.catalog_filter__toggler:hover {
  opacity: .7;
}
.catalog_filter__toggler:focus {
  outline: none;
}
.catalog_filter__quantity {
  height: 60px;
  float: left;
  margin-left: 16px;
  font-size: 15px;
  color: #898989;
}
.catalog_filter__layout {
  padding: 15px;
  float: right;
  height: 60px;
  margin-right: 150px;
}
.catalog_filter .layoutItem {
  float: left;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.catalog_filter__tile {
  background: url('../img/layoutTile.svg') no-repeat;
  margin-right: 20px;
}
.catalog_filter__tile.active,
.catalog_filter__tile:hover {
  background-position: -32px 0;
}
.catalog_filter__table {
  background: url('../img/layoutTable.svg') no-repeat;
  background-position: -1px 0;
}
.catalog_filter__table.active,
.catalog_filter__table:hover {
  background-position: -32px 0;
}
.catalog_filter__sorting {
  float: right;
  margin-right: 34px;
}
.catalog_filter__sorting .select_wrapper {
  position: relative;
  float: left;
  margin-right: 9px;
}
.catalog_filter__sorting .select_wrapper:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  right: 6px;
  top: 45%;
  transform: translateY(-50%) rotate(45deg);
  border: 4px solid #fff;
  border-color: transparent #898989 #898989 transparent;
}
.catalog_filter-bot {
  min-height: 90px;
  background-color: #fff;
  display: flex;
}
.catalog_filter-bot fieldset {
  padding: 8px 18px;
  border: 1px solid #E6E6E6;
  border-right: none;
  width: 100%;
}
.catalog_filter-bot fieldset:last-child {
  border-right: 1px solid #E6E6E6;
}
.catalog_filter-bot fieldset.feature-1 label {
  float: left;
  display: block;
  position: relative;
  width: 28px;
  height: 28px;
  border: 1px solid #E6E6E6;
  margin: 4px;
  overflow: hidden;
}
.catalog_filter-bot fieldset.feature-1 label img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.catalog_filter-bot fieldset.feature-1 label.checked:before {
  display: block;
  left: 20px;
  top: 11px;
  width: 1px;
  height: 10px;
  border: 1px solid #bbb;
  background-color: #555;
  transform: rotate(45deg);
  z-index: 30;
}
.catalog_filter-bot fieldset.feature-1 label.checked:after {
  display: block;
  left: 15px;
  top: 14px;
  width: 1px;
  height: 6px;
  border: 1px solid #bbb;
  background-color: #555;
  transform: rotate(-45deg);
  z-index: 30;
}
.catalog_filter-bot fieldset.feature-1 input {
  display: none;
}
.catalog_filter-bot fieldset.filter_price label {
  width: 100%;
  text-transform: uppercase;
}
.catalog_filter-bot fieldset.filter_price label:first-child {
  font-size: 15px;
}
.catalog_filter-bot fieldset legend {
  float: left;
  width: 100%;
  padding-left: 4px;
  font-size: 15px;
  text-transform: uppercase;
  color: #898989;
}
.catalog_filter-bot fieldset input[type="checkbox"] {
  display: none;
}
.catalog_filter-bot fieldset label {
  position: relative;
  float: left;
  display: block;
  width: 50%;
  height: 26px;
  line-height: 26px;
  padding-left: 28px;
  font-size: 12px;
  color: #898989;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.catalog_filter-bot fieldset label:before {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 4px;
  border: 1px solid #E6E6E6;
}
.catalog_filter-bot fieldset label.checked:after {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 8px;
  top: 5px;
  left: 6px;
  transform: rotate(45deg);
  border: 2px solid #898989;
  border-color: transparent #898989 #898989 transparent;
}
