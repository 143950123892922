@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'Segoe Script';
  src: url('../fonts/SegoeScript.eot?#iefix') format('embedded-opentype'), url('../fonts/SegoeScript.woff') format('woff'), url('../fonts/SegoeScript.ttf') format('truetype'), url('../fonts/SegoeScript.svg#SegoeScript') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Allegretto Script Two';
  src: url('../fonts/AllegrettoScriptTwo.eot?#iefix') format('embedded-opentype'), url('../fonts/AllegrettoScriptTwo.woff') format('woff'), url('../fonts/AllegrettoScriptTwo.ttf') format('truetype'), url('../fonts/AllegrettoScriptTwo.svg#AllegrettoScriptTwo') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Stylo Bold';
  src: url('../fonts/Stylo-Bold_gdi.eot');
  src: url('../fonts/Stylo-Bold_gdi.eot?#iefix') format('embedded-opentype'), url('../fonts/Stylo-Bold_gdi.woff') format('woff'), url('../fonts/Stylo-Bold_gdi.ttf') format('truetype'), url('../fonts/Stylo-Bold_gdi.svg#Stylo-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2014;
}
@font-face {
  font-family: 'BirchCTT';
  src: url('../fonts/BirchCTT_gdi.eot');
  src: url('../fonts/BirchCTT_gdi.eot?#iefix') format('embedded-opentype'), url('../fonts/BirchCTT_gdi.woff') format('woff'), url('../fonts/BirchCTT_gdi.ttf') format('truetype'), url('../fonts/BirchCTT_gdi.svg#BirchCTT-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2122;
}
@font-face {
  font-family: 'Pushkin';
  src: url('../fonts/Pushkin_gdi.eot');
  src: url('../fonts/Pushkin_gdi.eot?#iefix') format('embedded-opentype'), url('../fonts/Pushkin_gdi.woff') format('woff'), url('../fonts/Pushkin_gdi.ttf') format('truetype'), url('../fonts/Pushkin_gdi.svg#Pushkin-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0001-2026;
}
@font-face {
  font-family: 'Kaliakra';
  src: url('../fonts/Kaliakra_gdi.eot');
  src: url('../fonts/Kaliakra_gdi.eot?#iefix') format('embedded-opentype'), url('../fonts/Kaliakra_gdi.woff') format('woff'), url('../fonts/Kaliakra_gdi.ttf') format('truetype'), url('../fonts/Kaliakra_gdi.svg#Kaliakra-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-201D;
}
.buy_oneClick {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #A40000;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  outline: none;
  height: 36px;
  line-height: 36px;
  text-decoration: none;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.buy_oneClick:hover {
  opacity: .75;
}
.features {
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.features_item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.features_item__img-wrapper {
  position: relative;
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;
}
.features_item__img-wrapper img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.features_item__img-wrapper img {
  height: 46px;
}
.features_item__text {
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  max-width: 90px;
}
.product {
  display: flex;
}
.product .zoom_container {
  display: none;
  position: absolute;
  left: -35px;
  top: 12px;
  width: 600px;
  height: 500px;
  z-index: 200;
  overflow: hidden;
}
.product .zoom_container img {
  height: auto!important;
}
.product .zoom_container.active {
  display: block;
  box-shadow: 0 0 15px 4px #33261E;
  transition: box-shadow 0.2s;
}
.product_left {
  width: 40%;
  padding: 1%;
}
.product_right {
  position: relative;
  width: 58%;
  margin-left: 2%;
}
.product_title {
  font-size: 18px;
  color: #712C18;
  margin-bottom: 10px;
}
.product_price__block {
  margin-bottom: 10px;
}
.product_price {
  height: 36px;
  line-height: 36px;
  float: left;
  font-size: 33px;
  color: #33261E;
}
.product_price__old {
  display: none;
  float: left;
  min-width: 90px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  margin-left: 20px;
  text-align: center;
  font-size: 14px;
  text-decoration: line-through;
  background-color: #E6E6E6;
  color: #898989;
}
.product_buy {
  position: relative;
  width: 210px;
}
.product_buy .warningText {
  position: absolute;
  left: 120%;
  top: 0;
  width: 220px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  background-color: #A40000;
  box-shadow: inset 0 0 0 2px #A40000, inset 0 0 0 3px #E6E6E6;
}
.product_buy__amount {
  float: left;
  width: 65px;
}
.product_buy__amount input {
  border: 0;
}
.product_buy__amount input:focus {
  outline: none;
}
.product_buy__amount input:only-of-type {
  width: 30px;
  height: 36px;
  font-size: 15px;
  text-align: center;
}
.product_buy__amount div {
  float: right;
}
.product_buy__amount input[type="button"] {
  display: block;
  width: 30px;
  height: 16px;
  padding: 0;
  line-height: 16px;
  border: none;
  background-color: #E6E6E6;
  color: #898989;
  font-weight: 600;
  font-size: 15px;
  transition: all 0.4s ease-out;
}
.product_buy__amount input[type="button"]:first-child {
  margin-bottom: 4px;
}
.product_buy__amount .less:hover {
  background-color: #CD5C5C;
  color: #fff;
}
.product_buy__amount .more:hover {
  background-color: #90EE90;
  color: #fff;
}
.product_buy__button {
  position: relative;
  float: right;
  width: 140px;
  height: 36px;
  padding-left: 20px;
  border: 0;
  background-color: #A40000;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  outline: none !important;
  transition: all 0.4s ease-out;
}
.product_buy__button > .quant-good-bskt {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  line-height: 1.43;
  border: 1px solid #898989;
  border-radius: 50%;
  text-align: center;
  color: #111;
  background-color: #E6E6E6;
}
.product_buy__button:after {
  content: '';
  display: block;
  position: absolute;
  left: 18px;
  top: 11px;
  width: 18px;
  height: 16px;
  background: url('../img/cart_white.svg') no-repeat;
}
.product_buy__button:hover {
  opacity: .75;
}
.product_buy__button-not_in_stock {
  float: left;
  padding-left: 0;
  line-height: 36px;
  text-align: center;
}
.product_buy__button-not_in_stock:after {
  display: none;
}
.product .tab-panel {
  display: flex;
  margin: 64px 0 0 0;
}
.product .js_tab_btn {
  width: 100%;
  height: 38px;
  line-height: 38px;
  margin: 0 1px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #E6E6E6;
  color: #898989;
  font-size: 12px;
  border: 1px solid #E6E6E6;
  transition: all 0.3s ease-out;
}
.product .js_tab_btn.current {
  color: #7D4A2E;
  background-color: #fff;
  margin: 0 -1px;
  border-bottom-color: transparent;
}
.product .tab_content {
  margin-bottom: 40px;
}
.product .product_tab__recalls .product_tab__inner {
  padding-top: 0;
}
.product .product_tab__inner {
  padding-top: 20px;
  font-size: 15px;
  color: #898989;
  letter-spacing: .15em;
}
.product .product_tab__inner h1 {
  line-height: normal;
}
.product .product_tab__inner h4 {
  margin-bottom: 0;
  font-size: 18px;
  color: #A40000;
  font-weight: 600;
}
.product .product_tab__inner a {
  font-weight: 600;
  color: #33261E;
  transition: all 0.3s ease-out;
}
.product .product_tab__inner a:hover {
  text-decoration: none;
  opacity: .75;
}
.product .product_tab__inner--line {
  height: 1px;
  margin: 20px 0;
  background-color: #E6E6E6;
}
.product ul {
  padding-left: 20px;
  margin-top: 16px;
  list-style: disc;
}
.customize__trigger {
  display: none;
}
.customize__trigger_label {
  display: block;
  position: relative;
  font-size: 16px;
  color: #712C18;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  padding-left: 30px;
}
.customize__trigger_label strong {
  font-size: 20px;
}
.customize__trigger_label:after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #712C18;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.customize__trigger_label.checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 8px;
  top: 11px;
  left: 5px;
  transform: rotate(45deg);
  border: 2px solid #898989;
  border-color: transparent #712C18 #712C18 transparent;
}
.customize__content {
  display: none;
  padding: 12px 0;
}
.customize__photo {
  position: relative;
}
#customize_text-output {
  position: absolute;
  top: 45px;
  left: 25px;
  color: #ffe8c1;
  font-size: 20px;
  text-shadow: 0 -1px 1px #351600;
  font-family: 'Allegretto Script Two';
}
.customize_item {
  width: 464px;
}
.customize input {
  width: 300px;
  height: 36px;
  line-height: 36px;
  padding-left: 12px;
  font-size: 14px;
  margin-bottom: 12px;
  border: 1px solid #E6E6E6;
  color: #898989;
}
.customize input:focus,
.customize input:active {
  outline: none;
}
.customize select {
  width: 300px;
  height: 36px;
  line-height: 36px;
  padding-left: 12px;
  margin-bottom: 12px;
  border: 1px solid #E6E6E6;
  color: #898989;
  cursor: pointer;
}
.customize select:focus,
.customize select:active {
  outline: none;
}
.customize__label {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  width: 160px;
  text-align: left;
  font-size: 16px;
  color: #898989;
}
.customize option {
  height: 36px;
  line-height: 36px;
}
.multiSelect_img {
  height: 100px;
  width: 100px;
}
.product_right .order label {
  width: 100%;
  justify-content: space-around;
  margin-left: 0;
}
.select_title {
  width: 140px;
  text-align: right;
}
.product_right .product__feature_name {
  white-space: nowrap;
  margin-right: 20px;
  margin-top: 8px;
}
.product_right .product__feature_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
  padding: 0;
}
.product_right .product__feature_item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.product_right .product__feature_item.withImg {
  justify-content: space-around;
}
.product_right .product__feature_item.withImg .select__img_holder {
  position: relative;
  width: 100px;
  height: 40px;
}
.product_right .product__feature_item.withImg .product__feature_info {
  width: 244px;
  margin: 0;
}
.product_right .product__feature_item.withImg .product__feature_name {
  margin: 0;
  width: 140px;
  text-align: right;
}
.product_right .product__feature_item.withImg select {
  width: 244px;
  height: 36px;
}
.product_right .product__feature_item.withImg select:focus {
  outline-color: #898989;
}
.product_right .product__feature_item.withImg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product_right .product__feature_info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 12px;
  margin-top: 8px;
  margin-left: 8px;
  width: 100%;
  text-align: left;
}
.product_right .product__feature_val {
  margin-right: 20px;
}
.product_right .product__feature_img {
  position: relative;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 33px;
  z-index: 10;
}
.product_right .product__feature_img img {
  z-index: 1;
}
.product_right .product__feature_img img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.product_right .product__feature_block {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 22%;
  margin-right: 20%;
  min-width: 140px;
  max-width: 140px;
  padding: 5px 0;
}
