.category {
  margin-top: 10px;
}
.category_item__wrapper {
  display: block;
  float: left;
  width: 24%;
  margin: 8px 0;
  margin-right: 1.33%;
  overflow: hidden;
  background-color: #fefefe;
}
.category_item__wrapper:nth-child(4n) {
  margin-right: 0;
}
.category_item__wrapper:hover .category_title {
  background-color: #898989;
  color: #fff;
}
.category_title {
  display: block;
  height: 35px;
  line-height: 35px;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  color: #222;
  background-color: #E6E6E6;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.4s ease-out;
}
.category_img__wrapper {
  display: block;
  position: relative;
  height: 210px;
  overflow: hidden;
  z-index: 10;
}
.category_img__wrapper img {
  width: auto;
  z-index: 1;
}
.category_img__wrapper img {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease-out;
}
.category_img__wrapper:hover img {
  transform: translate(-50%, -50%) scale(1.15);
}
