footer {
  min-height: 316px;
  background: url('../img/wood_bg.png');
  background-size: cover;
  min-width: 1000px;
}
.footer_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 20px 20px 20px ;
}
footer .footer_menu a {
  display: block;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 400;
  color: #E6E6E6;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.37s ease-out;
}
footer .footer_menu a:hover {
  opacity: .75;
  text-decoration: underline;
}
footer .footer_menu ul > li {
  font-size: 15px;
  text-transform: uppercase;
  color: #E6E6E6;
  text-decoration: none;
  font-weight: 100;
  display: block;
  height: 26px;
  line-height: 26px;
}
footer .footer_menu ul > li > a {
  margin: 0;
  font-size: 15px;
  text-transform: uppercase;
  color: #E6E6E6;
  text-decoration: none;
  font-weight: 100;
  display: block;
  height: 26px;
  line-height: 26px;
}
footer .footer_social {
  margin: 12px 0 0 -6px;
}
footer .footer_social .social__item {
  filter: brightness(0.7);
}
footer .footer_social .social__item:hover {
  filter: brightness(1.2);
}
footer .footer_payment {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
}
footer .footer_payment__img {
  margin: 0 10px;
}
